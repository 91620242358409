import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Email, Phone, Cell } from "../components/contactData"

const KontaktPage = () => (
  <Layout>
    <Seo title="Kontakt" />


    <section className="section">

      {/* <div className="section"> */}

      <div className="container is-max-desktop">

        <div className="content">
          {/* <h1>Kontakt</h1> */}
          <h1 className="title is-1 mb-6">Kontakt</h1>

        </div>
        <div className="columns">
          <div className="column">
            <div className="content">

              <h6>mitbewegt - Praxis für Physiotherapie</h6>
              <p>
                Maxi Benndorf<br />
                Holbeinstraße 24<br />
                04229 Leipzig<br />
              </p>
              <address>
                Festnetz: <Phone /><br />
                Mobil: <Cell />
                <br /><br />
                E-Mail: <Email />
              </address>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <h6>Öffnungszeiten</h6>
              <table className="clean">
                <tbody>
                  <tr>
                    <td>Montag</td><td>8-15 Uhr</td>
                  </tr>
                  <tr>
                    <td>Dienstag</td><td>9-19 Uhr</td>
                  </tr>
                  <tr>
                    <td>Mittwoch</td><td>8-15 Uhr</td>
                  </tr>
                  <tr>
                    <td>Donnerstag</td><td>9-19 Uhr</td>
                  </tr>
                  <tr>
                    <td>Freitag</td><td>8-14 Uhr</td>
                  </tr>
                </tbody>
              </table>
              <p>und nach Vereinbarung</p>
            </div>
          </div>
        </div>

        <div className="content mt-6">
          <h3>Anfahrt</h3>
          <p className="subtitle is-5 mb-1 mt-5">Öffentliche Verkehrsmittel</p>
          <p>
            <b>Bus 74</b> - Haltestelle Karlsbrücke und ca. 3 Minuten Fußweg<br />
            <b>Tram 1 + 32</b> - Haltestelle Stieglitzstraße und ca. 5 Minuten Fußweg
          </p>
          <p className="subtitle is-5 mb-1 mt-5">Auto</p>
          <p>
            Parken ist in der Holbeinstraße, sowie in den umliegenden Straßen kostenlos
          </p>
          <p className="subtitle is-5 mb-1 mt-5">Fahrrad</p>
          <p>
            Das Fahrrad kann vor der Praxis im Hof abgestellt werden
          </p>
        </div>

        <div className="content mt-6">
          <iframe
            style={{ "borderRadius": "3px" }}
            id="maps"
            title="map"
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDui5GS3Hq0tLFee2J3e38G2JqLK2k5fG4&q=mitbwegt+Physiotherapie+Leipzig+Deutschland">
          </iframe>
        </div>


      </div>
      {/* </div> */}
    </section>

  </Layout >
)

export default KontaktPage
